import React from "react";
import "./styles.css";

const randomNumber = () => {
  return Math.random() * 1.5 + 0.4;
};

const Waves = () => {
  return (
    <div className="music">
      <div className="soundwave">
        <div className="placeholder"></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-70"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-60"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-100"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-50"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-80"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-40"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-10"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-20"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-30"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
        <div
          className="freq h-5"
          style={{ animationDuration: `${randomNumber()}s` }}
        ></div>
      </div>
    </div>
  );
};

export default Waves;
